<template>

<div id="dashboard-content">
    <div class="mb-4 d-flex">
      <h4>ISBN</h4>
      <router-link
        to="pengajuan-isbn/add"
        class="btn rounded-pill btn-secondary text-white ml-auto"
      >
        <i class="fa fa-circle-plus"></i> Tambah ISBN
      </router-link>
    </div>

    <template v-if="isbn.length == 0">
      <div class="text-center mt-5">
        <img width="60" src="@/assets/not-found.png" class="img-fluid" alt="" />
        <h4 class="text-center mt-2">Data tidak ditemukan.</h4>
      </div>
    </template>
    <template v-else>
      <div v-if="$store.state.loadPage" class="loading text-center">
        <img src="@/assets/loading-2.gif" alt="" class="img-fluid" />
      </div>
      <div v-else class="card border-0 shadow-sm p-3">
        <div class="mb-4">
          Layanan ISBN untuk usul baru akan ditutup pada tanggal 13 Desember 2023. Seluruh usulan yang ditolak segera diusulkan paling lambat tanggal 14 Desember 2023.
        </div>
        <div class="input-group mb-3 w-50">
          <div class="input-group-prepend">
            <span
              class="input-group-text bg-white border-right-0"
              id="basic-addon1"
              ><i class="fa fa-search"></i
            ></span>
          </div>
          <input
            type="text"
            class="form-control border-left-0"
            placeholder="Cari kata kunci"
            v-model="keyword"
          />
        </div>
        <div class="table-responsive">
          <table class="table mt-4">
            <thead class="bg-grey-200">
              <tr class="rounded-pill">
                <th scope="col">Judul</th>
                <th scope="col">Status</th>
                <th scope="col">Nomor ISBN</th>
                <th scope="col">Barcode</th>
                <th scope="col">Opsi</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="filteredISBN.length == 0">
                <td colspan="5" class="text-center">Pencarian tidak ditemukan</td>
              </tr>
              <tr v-else v-for="(item, index) in filteredISBN" :key="index">
                <td>{{ item.judul }}</td>
                <td class="text-capitalize">
                  <span v-if="item.status == 'Terkirim' || item.status == 'publish'"><span class="fa fa-paper-plane text-info"></span> Terkirim</span>
                  <span v-if="item.status == 'Diterima'"><span class="fa fa-check text-success"></span> {{ item.status }}</span>
                  <span v-if="item.status == 'Ditolak'" ><span class="fa fa-ban text-warning"></span> {{ item.status }}</span>
                  <span v-if="item.status == 'Revisi'" ><span class="fa fa-info-circle text-warning"></span> {{ item.status }}</span>
                  <span v-if="item.status == 'Selesai'"><span class="fa fa-thumbs-up text-success"></span> {{ item.status }}</span>
                </td>
                <td>{{ item.nomor_isbn ? item.nomor_isbn : '-' }}</td>
                <td>
                  <a v-if="item.barcode" :href="item.barcode.includes('drive') ? item.barcode : `https://admin.buku.kemdikbud.go.id/uploads/sibi/entry_files/${item.barcode}`" target="_blank" class="badge bg-primary text-white" style="cursor: pointer;"><i class="fa fa-barcode"></i> Barcode</a>
                  <span v-else>-</span>
                </td>
                <td>
                  <div class="dropdown">
                    <button class="btn btn-sm rounded-0 bg-white" type="button" id="dropdownMenuButton" data-toggle="dropdown"
                      aria-haspopup="true" aria-expanded="false">
                      <i class="fa fa-ellipsis"></i>
                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                      <a v-if="item.status == 'Selesai'" class="dropdown-item p-1">
                        <button @click="getDetail(item.id)" type="button"
                          class="btn badge bg-success text-white p-2 rounded-pill w-100" data-toggle="modal"
                          data-target="#buktiTerimaModal">
                          <i class="fa fa-circle-check"></i> Bukti Terima
                        </button>
                      </a>
                      <a class="dropdown-item p-1">
                        <button @click="getDetail(item.id)" type="button"
                          class="btn badge bg-primary text-white p-2 rounded-pill w-100" data-toggle="modal"
                          data-target="#detailModal">
                          <i class="fa fa-eye"></i> Detail
                        </button>
                      </a>
                      <a v-if="!item.nomor_isbn && item.status == 'Ditolak'" class="dropdown-item p-1">
                        <router-link :to="{ name: 'ISBN-Edit', params: { id: item.id } }">
                          <span class="badge bg-info text-white p-2 rounded-pill w-100">
                            <i class="fa fa-pencil-alt"></i> Edit
                          </span>
                        </router-link>
                      </a>
                      <a v-if="!item.nomor_isbn && item.status == 'Terkirim'" class="dropdown-item p-1">
                        <span style="cursor:pointer" @click="deleteISBN(item.id)"
                          class="badge bg-danger text-white p-2 rounded-pill w-100">
                          <i class="fa fa-trash"></i> Hapus
                        </span>
                      </a>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </template>

    <!-- Bukti Terima Modal -->
    <div class="modal fade" id="buktiTerimaModal" tabindex="-1" role="dialog" aria-labelledby="buktiTerimaLabel"
      aria-hidden="true">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="buktiTerimaLabel">Bukti Terima</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p v-if="!payload.bukti_terima" class="small">Selamat atas selesainya proses pengajuan ISBN pada buku bapak/ibu. Kami mendeteksi bahwa buku ini belum menyematkan bukti terima. Silahkan untuk unggah bukti terima seperti screenshot resi, foto bukti terima dan sebagainya pada form dibawah ini.</p>
            <form @submit.prevent="updateBukti()" enctype="multipart/form-data" accept-charset="utf-8">
              <div class="row">
                <div class="col-md-12 my-2">
                  <div class="row d-flex align-items-center">
                    <div class="col-md-5">
                      <div class="form-group">
                        <label>Bukti Terima (PDF) Maks 1,5 MB</label>
                        <input type="hidden" :value="detail.id">
                        <input v-model="payload.bukti_terima" type="hidden" class="form-control" />
                        <small v-if="messageError.bukti_terima !== ''" class="text-danger d-block">{{ messageError.bukti_terima }}</small>
                        <input @change="selectFile($event, 'bukti_terima')" type="file" class="form-control" :required="required.bukti_terima" />
                        <a v-if="payload.bukti_terima" :href="`https://static.buku.kemdikbud.go.id/uploader/files/${payload.bukti_terima}`" target="_blank" class="text-dark small my-2 d-block" style="cursor: pointer"><i class="fa fa-file-pdf"></i> Lihat Bukti Terima</a>
                      </div>
                      <div v-show="messageStatus.bukti_terima" class="mt-2 alert alert-success">
                        {{ message.bukti_terima }}
                      </div>
                      <button @click="uploadingFile('bukti_terima')" v-if="!loading.bukti_terima" type="button" class="btn btn-primary btn-block">
                        {{ payload.bukti_terima ? 'Unggah Ulang' : 'Unggah' }}
                      </button>
                      <button type="button" v-if="loading.bukti_terima" class="btn btn-primary btn-block">
                        <span class="spinner-border spinner-border-sm"></span>
                        Sedang diproses
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <p class="small mt-4">* Setelah mengunggah bukti terima. Jangan lupa klik tombol simpan dibawah ini.</p>

              <div v-show="failedRequest" class="alert alert-warning alert-dismissible fade show" role="alert">
                Terjadi kesalahan teknis
                <button @click="failedRequest = false" type="button" class="close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="text-right">
                <button v-if="$store.state.loadPage" class="btn btn-primary">
                  <span class="spinner-border spinner-border-sm"></span>
                  Sedang diproses..
                </button>
                <button v-else type="submit" class="btn btn-primary">Simpan</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <!-- Detail Modal -->
    <div class="modal fade" id="detailModal" tabindex="-1" role="dialog" aria-labelledby="detailModalLabel"
      aria-hidden="true">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="detailModalLabel">Detail Informasi</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <table class="table table-stripped mt-1 text-center">
              <tr>
                <td width="10">Judul</td>
                <td width="1">:</td>
                <th>{{ detail.judul || '-' }}</th>
              </tr>
              <tr>
                <td width="10">Kepengarangan</td>
                <td width="1">:</td>
                <th>{{ detail.kepengarangan || '-' }}</th>
              </tr>
              <tr>
                <td width="10">Sinopsis</td>
                <td width="1">:</td>
                <th>{{ detail.sinopsis || '-' }}</th>
              </tr>
              <tr>
                <td width="10">Distributor</td>
                <td width="1">:</td>
                <th>{{ detail.distributor || '-' }}</th>
              </tr>
              <tr>
                <td width="10">Edisi</td>
                <td width="1">:</td>
                <th>{{ detail.edisi || '-' }}</th>
              </tr>
              <tr>
                <td width="10">Seri</td>
                <td width="1">:</td>
                <th>{{ detail.seri || '-' }}</th>
              </tr>
              <tr>
                <td width="10">Tahun Terbit</td>
                <td width="1">:</td>
                <th>{{ detail.tahun_terbit || '-' }}</th>
              </tr>
              <tr>
                <td width="10">Jumlah Halaman</td>
                <td width="1">:</td>
                <th>{{ detail.jumlah_halaman || '-' }}</th>
              </tr>
              <tr>
                <td width="10">Tinggi Buku</td>
                <td width="1">:</td>
                <th>{{ detail.tinggi_buku || '-' }}</th>
              </tr>
              <tr>
                <td width="10">Kelompok Pembaca</td>
                <td width="1">:</td>
                <th>{{ detail.kelompok_pembaca || '-' }}</th>
              </tr>
              <tr>
                <td width="10">Jenis Pustaka</td>
                <td width="1">:</td>
                <th>{{ detail.jenis_pustaka || '-' }}</th>
              </tr>
              <tr>
                <td width="10">Kategori Jenis</td>
                <td width="1">:</td>
                <th>{{ detail.kategori_jenis || '-' }}</th>
              </tr>
              <tr>
                <td width="10">Kategori Buku</td>
                <td width="1">:</td>
                <th>{{ detail.kategori_buku || '-' }}</th>
              </tr>
              <tr>
                <td width="10">Terbitan</td>
                <td width="1">:</td>
                <th>{{ detail.terbitan || '-' }}</th>
              </tr>
              <tr>
                <td width="10">Media</td>
                <td width="1">:</td>
                <th>{{ detail.media || '-' }}</th>
              </tr>
              <tr>
                <td width="10">Penelitian</td>
                <td width="1">:</td>
                <th>{{ detail.penelitian || '-' }}</th>
              </tr>
              <tr>
                <td width="10">Status</td>
                <td width="1">:</td>
                <th class="text-capitalize">
                  <span v-if="detail.status == 'Terkirim' || detail.status == 'publish'"><span class="fa fa-paper-plane text-info"></span> Terkirim</span>
                  <span v-if="detail.status == 'Diterima'"><span class="fa fa-check text-success"></span> {{ detail.status }}</span>
                  <span v-if="detail.status == 'Ditolak'" ><span class="fa fa-ban text-warning"></span> {{ detail.status }}</span>
                  <span v-if="detail.status == 'Revisi'" ><span class="fa fa-info-circle text-warning"></span> {{ detail.status }}</span>
                  <span v-if="detail.status == 'Selesai'"><span class="fa fa-thumbs-up text-success"></span> {{ detail.status }}</span>
                </th>
              </tr>
              <tr>
                <td width="10">Nomor ISBN</td>
                <td width="1">:</td>
                <th>{{ detail.nomor_isbn || '-' }}</th>
              </tr>
              <tr>
                <td width="10">ISBN Susulan</td>
                <td width="1">:</td>
                <th>{{ detail.isbn_susulan }}</th>
              </tr>
               <tr>
                <td width="10">Keterangan</td>
                <td width="1">:</td>
                <th>{{ detail.keterangan || '-'}}</th>
              </tr>
              <tr>
                <td width="10">Lampiran Cover, Prelim, SKK & Surat Pengantar</td>
                <td width="1">:</td>
                <th>
                  <a v-if="detail.lampiran" :href="`https://static.buku.kemdikbud.go.id/uploader/files/${detail.lampiran}`" target="_blank" class="badge bg-info text-white">
                    <i class="fa fa-download"></i> Unduh
                  </a>
                  <span v-else>-</span>
                </th>
              </tr>
              <!-- <tr>
                <td width="10">Prelim</td>
                <td width="1">:</td>
                <th>
                  <a v-if="detail.prelim" :href="`https://static.buku.kemdikbud.go.id/uploader/files/${detail.prelim}`" target="_blank" class="badge bg-info text-white">
                    <i class="fa fa-download"></i> Unduh
                  </a>
                  <span v-else>-</span>
                </th>
              </tr>
              <tr>
                <td width="10">Buku Dummy</td>
                <td width="1">:</td>
                <th>
                  <a v-if="detail.dummy" :href="`https://static.buku.kemdikbud.go.id/uploader/files/${detail.dummy}`" target="_blank" class="badge bg-info text-white">
                    <i class="fa fa-download"></i> Unduh
                  </a>
                  <span v-else>-</span>
                </th>
              </tr> -->
              <tr>
                <td width="10">Barcode</td>
                <td width="1">:</td>
                <th>
                  <a v-if="detail.barcode" :href="detail.barcode" target="_blank" class="badge bg-info text-white" style="cursor: pointer;">
                    <i class="fa fa-barcode"></i> Barcode
                  </a>
                  <span v-else>-</span>
                </th>
              </tr>
              <tr>
                <td width="10">Cover</td>
                <td width="1">:</td>
                <th>
                  <a v-if="detail.cover" :href="`https://static.buku.kemdikbud.go.id/uploader/files/${detail.cover}`" target="_blank" class="text-dark small" style="cursor: pointer"><i class="fa fa-image"></i> Lihat Cover</a>
                  <span v-else>-</span>
                </th>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>    
  </div>

</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "ISBN",
  data() {
    return {
      detail: {},
      failedRequest: false,
      keyword: "",
      payload: {
        id: "",
        bukti_terima: "",
      },
      required: {
        bukti_terima: false,
      },
      loading: {
        bukti_terima: false,
      },
      file: {
        bukti_terima: null,
      },
      messageStatus: {
        bukti_terima: '',
      },
      message: {
        bukti_terima: '',
      },
      messageError: {
        bukti_terima: '',
      },
    }
  },
  computed: {
    ...mapState(["isbn"]),
    filteredISBN() {
      return this.isbn.filter((isbn) => {
        return isbn.judul
          .toLowerCase()
          .includes(this.keyword.toLowerCase());
      });
    },
  },
  methods: {
    ...mapActions(["fetchAllISBN", "deleteISBNById", "uploadFile", "updateBuktiTerima"]),
    selectFile(e, state) {
      const file = e.target.files[0];
      this.file[state] = file;
    },
    uploadingFile(state) {
      if (this.file[state] === null) {
        this.messageError[state] = `File harus diisi`;
      } else {
        if (this.file[state].size >= 1500000) {
          this.messageError[state] = "Ukuran file terlalu besar, maksimal 1,5 mb";
        } else {
          this.loading[state] = true;
          this.messageError[state] = "";
          this.uploadFile(this.file[state])
            .then((res) => {
              if (res.status === "success" || res.status == 200) {
                this.payload[state] = res.file;
                this.messageStatus[state] = true;
                state !== 'cover' ? this.message[state] = "Berhasil diunggah, selanjutnya silahkan klik tombol simpan" : null;
              } else {
                this.messageError[state] = res.message;
              }
            })
            .catch((err) => {
              this.messageError[state] = "File gagal diupload";
              this.$store.state.loadUploadFile = false;
              console.log(err);
            })
            .finally(() => this.loading[state] = false);
        }
      }
    },
    updateBukti() {
      if (this.payload.bukti_terima === "") {
        this.messageError.bukti_terima = "Silahkan upload berkas bukti terima terlebih dahulu";
      } else {
        this.updateBuktiTerima(this.payload)
        .then(() => {
          this.$router.push("/user/pengajuan-isbn");
        })
        .catch(() => {
          this.failedRequest = true;
        });
      }
    },
    deleteISBN(id) {
      const confirm = window.confirm("Apakah anda yakin akan menghapus?");
      confirm && this.deleteISBNById(id);
    },
    getBarcode(lampiran) {
      return lampiran
    },
    getDetail(id) {
      const data = this.isbn.filter((item) => item.id == id)
      this.detail = data[0];
      
      // Set data bukti terima
      this.payload.id = this.detail.id
      this.payload.bukti_terima = this.detail.bukti_terima
    }
  },
  created() {
    this.fetchAllISBN();
    this.$store.state.messageStatusISBN = false;
  },
  beforeCreate() {
    const logged_in = localStorage.getItem("user");
    if (!logged_in) {
      this.$router.push("/login");
    }
    // Check role name
    const { role_name } = JSON.parse(localStorage.getItem('user'))
    if (role_name !== 'Satker') this.$router.push('/user/home')
  },
};
</script>